import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import Hero from '../components/hero';

const Page = ({ data }) => { 
  return (
    <Layout>
      <Hero title={data.markdownRemark.frontmatter.title} description={data.markdownRemark.frontmatter.subtitle} />
      <div className="m-rich-text" dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }} />
    </Layout>
  );
};

export const pageQuery = graphql`
  query GetStaticPage($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      id
      frontmatter {
        title
        subtitle
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      html
    }
  }
`;

export default Page;
